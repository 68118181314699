<template>
  <div class="service-form-detail" v-if="getPermission('service-form:view')">
    <v-container fluid class="custom-bthrust-style">
      <v-container>
        <v-layout class="justify-end mb-1">
          <v-spacer></v-spacer>
          <!--  <div class="ml-2 text-right"> -->
          <v-autocomplete
            v-model.trim="filter.trip_id"
            class="mr-2"
            hide-details
            :items="teams"
            clearable
            dense
            flat
            filled
            label="Trip"
            item-color="cyan"
            style="max-width: 350px"
            color="cyan"
            solo
            :disabled="serviceFormLoading"
            item-text="barcode"
            item-value="id"
            v-on:change="getServiceForm"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Trip(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                {{ item.barcode }} ({{ formatDate(item.trip_start) }}
                {{ formatDateTimeStart(item.trip_start) }} -
                {{ formatDate(item.trip_end) }}
                {{ formatDateTimeStart(item.trip_end) }})
              </p>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title
                  class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                  >{{ item.barcode }} ({{ formatDate(item.trip_start) }}
                  {{ formatDateTimeStart(item.trip_start) }} -
                  {{ formatDate(item.trip_end) }}
                  {{ formatDateTimeStart(item.trip_end) }})</v-list-item-title
                >
                <!--  <v-list-item-subtitle
                      class="font-size-14 pl-2 text-ellipsis pb-1"
                      >{{ item.title }}</v-list-item-subtitle
                    > -->
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            style="max-width: 350px"
            label="Search"
            hide-details
            solo
            :disabled="serviceFormLoading"
            color="cyan"
            @keydown.enter="getServiceForm"
            @keydown.tab="getServiceForm"
          ></v-text-field>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                class="mr-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="serviceFormLoading"
                v-on:click="getServiceForm"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                class="ml-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="serviceFormLoading"
                v-on:click="getServiceForm"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Reload</span>
          </v-tooltip>
          <template v-if="false && getPermission('service-form:create')">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 custom-bold-button white--text"
                  color="cyan"
                  :disabled="serviceFormLoading"
                  v-on:click="createServiceForm"
                >
                  <v-icon>mdi-file-document-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Create Service Form</span>
            </v-tooltip>
          </template>
          <!--  </div> -->
        </v-layout>
      </v-container>
      <v-skeleton-loader
        type="text@5"
        class="custom-skeleton table-rows-text"
        v-if="serviceFormLoading"
      ></v-skeleton-loader>
      <v-simple-table
        v-else
        class="inner-simple-table custom-border-top"
        fixed-header
      >
        <template v-slot:default>
          <tbody>
            <template v-if="lodash.isEmpty(serviceForms) === false">
              <tr class="custom-border-bottom">
                <td class="font-size-16 pl-3 py-2 font-weight-700">
                  Service #
                </td>
                <td class="font-size-16 pl-3 py-2 font-weight-700">
                  Timesheet #
                </td>
                <td class="font-size-16 pl-3 py-2 font-weight-700">Trip #</td>
                <td class="font-size-16 pl-3 py-2 font-weight-700">
                  Service Form
                </td>
                <td
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  v-if="!isVisitDetail"
                >
                  Visit
                </td>
                <td
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  v-if="!isVisitDetail"
                >
                  Engineer
                </td>
                <td
                  width="150"
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  align="center"
                >
                  Status
                </td>
                <!--    <td
                  width="150"
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  align="center"
                >
                  Voice Notes
                </td> -->
                <!-- <td
                  width="250"
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  align="center"
                >
                  <template v-if="visit.status != 4">Preview /</template>
                  Download
                </td> -->
                <td
                  width="250"
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  align="center"
                >
                  Preview
                </td>
                <td
                  width="250"
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  align="center"
                >
                  Download
                </td>
                <td
                  width="150"
                  class="font-size-16 pl-3 py-2 font-weight-700"
                  align="center"
                >
                  System Upload
                </td>
              </tr>
              <template v-for="(row, index) in serviceForms">
                <v-hover v-slot="{ hover }" :key="index">
                  <tr>
                    <!-- <pre>{{row}}</pre> -->
                    <!--  <td
                      class="cursor-pointer font-size-16 font-weight-500"
                      width="200"
                      v-on:click="serviceFormDetail(row)"
                    > -->
                    <td
                      class="cursor-pointer font-size-16 font-weight-500"
                      width="200"
                    >
                      <template v-if="row.trip">
                        <Barcode :barcode="getBarcodeBytrip(row)"></Barcode>
                      </template>
                      <template v-else>
                        <Barcode :barcode="getBarcode(row)"></Barcode>
                      </template>

                      <!--  <v-chip
                      
                        style="height: 16px; width: 16px; padding: 0"
                        :color="
                          row && !row.document  ? 'red' : 'green'
                        "
                        class="ml-1"
                      >
                      </v-chip> -->
                      <v-icon
                        v-if="row && row.need_cost == 1"
                        :color="'red'"
                        style="height: 16px; width: 16px; padding: 0"
                        class="ml-1"
                      >
                        mdi-flag</v-icon
                      >
                    </td>
                    <td
                      class="cursor-pointer font-size-16 font-weight-500"
                      width="200"
                    >
                      <span v-if="row.timesheet">
                        <Barcode :barcode="row.timesheet.barcode"></Barcode>
                      </span>
                    </td>
                    <td
                      class="cursor-pointer font-size-16 font-weight-500"
                      width="200"
                    >
                      <span v-if="row.trip && row.trip.barcode">
                        <Barcode :barcode="row.trip.barcode"></Barcode>
                      </span>
                      <em class="text-muted" v-else> no trip </em>
                    </td>
                    <td class="cursor-pointer font-size-16 font-weight-500">
                      <p class="m-0 py-2 custom-nowrap-ellipsis">
                        {{ row.title }}
                      </p>
                    </td>
                    <td
                      class="cursor-pointer font-size-16 font-weight-500"
                      v-if="!isVisitDetail"
                    >
                      <template v-if="lodash.isEmpty(row.visit) === false">
                        <Barcode :barcode="row.visit.barcode"></Barcode>
                        <p
                          class="m-0 custom-nowrap-ellipsis font-size-16 font-weight-500"
                        >
                          {{ formatDateTime(row.visit.started_at) }} -
                          {{ formatTime(row.visit.finished_at) }}
                        </p>
                      </template>
                      <template v-else> - </template>
                    </td>
                    <td
                      class="cursor-pointer font-size-16 font-weight-500"
                      v-if="!isVisitDetail"
                    >
                      <p
                        class="m-0 py-2 custom-nowrap-ellipsis"
                        v-if="lodash.isEmpty(row.visit) === false"
                        :class="{
                          'text--secondary': lodash.isEmpty(
                            row.visit.engineers
                          ),
                        }"
                      >
                        {{ engineerDisplayText(row.visit.engineers) }}
                      </p>
                      <p
                        v-else
                        class="m-0 py-2 custom-nowrap-ellipsis text--secondary"
                      >
                        {{ engineerDisplayText() }}
                      </p>
                    </td>
                    <td
                      width="150"
                      class="font-size-16 font-weight-500"
                      align="center"
                    >
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            class="mx-2 d-inline custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                            color="cyan"
                            text-color="white"
                            label
                            small
                          >
                            <template v-if="row.is_filled">Submitted</template>
                            <template v-else>Not Submitted Yet</template>
                          </v-chip>
                        </template>
                        <span>Status</span>
                      </v-tooltip>
                    </td>
                    <!--    <td
                      width="200"
                      class="font-size-16 font-weight-500"
                      align="center"
                    >
                      <v-icon
                        class="custom-grey-border custom-bold-button white--text"
                        :disabled="
                          row && row.voice_notes && row.voice_notes.length
                            ? false
                            : true
                        "
                        color="green"
                        v-on:click="showNotes(row)"
                        >mdi-play-circle</v-icon
                      >
                      <div
                        class="font-size-16 font-weight-700 red--text"
                        v-if="
                          row &&
                          row.voice_notes &&
                          row.voice_notes.length &&
                          row.voice_notes.length > 1
                        "
                        v-on:click="showNotes(row)"
                      >
                        +{{ row.voice_notes.length - 1 }} More
                      </div>
                    </td> -->
                    <td
                      width="150"
                      class="font-size-16 font-weight-500"
                      align="center"
                    >
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!row.view_pdf_url"
                            color="cyan"
                            v-on:click.stop.prevent="
                              downloadFile(row.view_pdf_url)
                            "
                            icon
                          >
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>
                        <span>Preview PDF</span>
                      </v-tooltip>
                      <!--  <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!row.view_pdf_url"
                            color="cyan"
                            v-on:click.stop.prevent="share_service_report(row)"
                            icon
                            class="ml-4"
                          >
                            <v-icon>mdi-share</v-icon>
                          </v-btn>
                        </template>
                        <span>Share {{ row.title }}</span>
                      </v-tooltip> -->
                    </td>
                    <td
                      width="150"
                      class="font-size-16 font-weight-500"
                      align="center"
                    >
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="visit?.closer?.id ? false : true"
                            color="cyan"
                            v-on:click.stop.prevent="
                              downloadFile(row.view_pdf_url)
                            "
                            icon
                          >
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-btn>
                        </template>
                        <span>Download PDF</span>
                      </v-tooltip>

                      <!--  <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!row.pdf_url"
                            color="cyan"
                            v-on:click.stop.prevent="share_service_report(row)"
                            icon
                            class="ml-4"
                          >
                            <v-icon>mdi-share</v-icon>
                          </v-btn>
                        </template>
                        <span>Share {{ row.title }}</span>
                      </v-tooltip> -->
                    </td>
                    <td
                      width="150"
                      class="font-size-16 font-weight-500"
                      align="center"
                    >
                      <template v-if="row">
                        <v-tooltip
                          top
                          content-class="custom-top-tooltip"
                          v-if="row && !row.document && row.visit"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              :disabled="row && row.need_cost == 0"
                              color="cyan"
                              v-on:click.stop.prevent="uploadFile(row)"
                              icon
                            >
                              <v-icon>mdi-paperclip</v-icon>
                            </v-btn>
                          </template>
                          <span>Upload File</span>
                        </v-tooltip>
                        <template v-if="row.document && row.document">
                          <template
                            v-if="
                              row.document &&
                              row.document.extension &&
                              isImage(row.document.extension)
                            "
                          >
                            <span
                              class="cursor-pointer"
                              v-on:click.stop.prevent="
                                downloadFile(row.document.file.url)
                              "
                            >
                              <ImageTemplate
                                :src="row.document.file.url"
                                style="max-width: 50px; width: 50px"
                              ></ImageTemplate>
                            </span>
                          </template>
                          <template v-else>
                            <inline-svg
                              style="max-width: 30px; width: 30px"
                              v-on:click.stop.prevent="
                                downloadFile(row.document.file.url)
                              "
                              :src="
                                $assetURL(
                                  `media/mime/${row.document.extension}.svg`
                                )
                              "
                            />
                          </template>
                        </template>
                      </template>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </template>
            <tr v-else>
              <td colspan="4" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no service form at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Dialog :commonDialog="detailDialog" :dialogWidth="dialogWidth">
        <template v-slot:title>
          <v-row>
            <v-col md="8">
              <p class="m-0 custom-nowrap-ellipsis">
                {{ serviceFormInfo.title }}
              </p>
            </v-col>
            <v-col md="4">
              <v-layout class="justify-end">
                <template
                  v-if="
                    serviceFormInfo.can_update &&
                    getPermission('service-form:update')
                  "
                >
                  <v-btn
                    :class="{ 'pointer-events-none': is_staff }"
                    v-if="!serviceFormInfo.is_filled"
                    :loading="formLoading"
                    :disabled="
                      formLoading ||
                      !submitChckouthide() ||
                      !submitbuttonloginform()
                    "
                    v-on:click="startSaveForm('save')"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    :class="{ 'pointer-events-none': is_staff }"
                    v-if="!serviceFormInfo.is_filled"
                    :loading="formLoading"
                    :disabled="
                      formLoading ||
                      !submitChckouthide() ||
                      !submitbuttonloginform()
                    "
                    v-on:click="startSaveForm('submit')"
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                  >
                    Submit
                  </v-btn>
                </template>
                <v-menu
                  v-if="false"
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :class="{ 'pointer-events-none': is_staff }"
                      :disabled="
                        formLoading ||
                        !submitChckouthide() ||
                        !submitbuttonloginform()
                      "
                      class="mx-2 custom-bold-button white--text"
                      color="cyan"
                      v-bind="attrs"
                      v-on="on"
                    >
                      More... <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template>
                      <v-list-item
                        :disabled="!serviceFormInfo.view_pdf_url"
                        v-on:click="downloadFile(serviceFormInfo.view_pdf_url)"
                        link
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-file-pdf-box</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >PDF Download</v-list-item-title
                        >
                      </v-list-item>
                      <template v-if="getPermission('service-form:delete')">
                        <v-list-item
                          :disabled="serviceFormInfo.is_filled"
                          v-on:click="deleteServiceForm"
                          link
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue"
                              >mdi-delete</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >Delete</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </v-menu>
                <v-btn
                  v-on:click="closeDialog"
                  :disabled="formLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  >Close
                </v-btn>
              </v-layout>
            </v-col>
          </v-row>
        </template>
        <template v-slot:body>
          <v-form
            ref="serviceDataForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="startSaveForm('')"
            class="m-4"
          >
            <ServiceFormTemplate
              :serviceForm="serviceFormInfo"
              :detail="detail"
              :customer="customer"
              :property="property"
              :billing="billing"
              :contactPerson="contactPerson"
            ></ServiceFormTemplate>
          </v-form>
          <template v-if="getPermission('service-form:delete')">
            <ServiceFormDelete
              :deleteDialog="ServiceFormDeleteDialog"
              :requestUrl="ServiceFormDeleteRequestURL"
              v-on:delete:success="ServiceFormDeleteSuccess"
              v-on:delete:close="ServiceFormDeleteDialog = false"
            ></ServiceFormDelete>
          </template>
        </template>
      </Dialog>
      <template v-if="serviceFormDialog">
        <CreateServiceForm
          :isVisitDetail="isVisitDetail"
          :serviceFormDialog="serviceFormDialog"
          :detail="detail"
          :visit="visit"
          v-on:create:service-form="getServiceForm"
          v-on:close-dialog="serviceFormDialog = false"
        ></CreateServiceForm>
      </template>
      <ShareServiceReport
        :mail-dialog="share_dialog"
        title="Share Service Forms"
        v-on:close="share_dialog = false"
        :visit-id="visitId"
        :report-id="reportId"
        do-internal
        v-on:success="getServiceForm()"
        v-on:open="share_dialog = true"
      ></ShareServiceReport>
      <SingleDocumentwithName
        :document-dialog="add_document_dialog"
        v-on:close="add_document_dialog = false"
        v-on:success="getServiceForm()"
        :document-type="303"
        :document-id="typeId"
        :extensive="false"
        :multiple="true"
        :parent-type="'visit'"
        :parent-type-id="visit.id"
      >
      </SingleDocumentwithName>
      <CloserSignature
        :mail-dialog="add_signature_dialog"
        v-on:close="add_signature_dialog = false"
        v-on:success="getServiceForm()"
        :service-id="typeId"
        :parent-type="'visit'"
        :parent-type-id="visit.id"
      >
      </CloserSignature>
      <Dialog :common-dialog="show_voice_notes">
        <template v-slot:title
          >Voice Notes ({{ voice_notes_list.length }})
          <!-- ({{ enquiry_barcode }}) :- Quotations --></template
        >

        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="pt-0 custom-listing-table">
                <template>
                  <table width="100%" class="white-background">
                    <thead>
                      <tr class="custom-border-bottom">
                        <td class="font-size-16 font-weight-700">S/N</td>
                        <td class="font-size-16 font-weight-700">Note</td>
                        <td class="font-size-16 font-weight-700">Added BY</td>
                        <td class="font-size-16 font-weight-700">Added At</td>
                      </tr>
                    </thead>
                    <!--   <tbody class="custom-border-bottom"> -->
                    <template v-for="(row, EIndex) in voice_notes_list">
                      <tr :key="EIndex" class="custom-border-bottom">
                        <td class="simple-table-td">{{ EIndex + 1 }}</td>
                        <td class="simple-table-td" align="left">
                          <!--   <div class="d-flex">
                                  <div>02/02/2014 03:PM </div>
                                  <v-spacer></v-spacer>
                                  <div class="font-weight-700">  Mr. John Deo</div>
                              </div> -->
                          <audio
                            type="audio/mp3"
                            color="cyan"
                            controls
                            :src="row.file.url"
                          ></audio>
                        </td>
                        <td class="simple-table-td">
                          <span
                            class="font-weight-700 text-capitalize"
                            v-if="
                              row && row.added_by && row.added_by.display_name
                            "
                          >
                            {{ row.added_by.display_name }}
                          </span>
                        </td>
                        <td class="simple-table-td">
                          <v-chip
                            color="#24326d"
                            small
                            outlined
                            text-color=""
                            label
                          >
                            {{ formatDateStartTime(row.added_at) }}</v-chip
                          >
                        </td>
                      </tr>
                    </template>

                    <!--  </tbody> -->
                  </table>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="show_voice_notes = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CloserSignature from "@/view/pages/partials/Closer-Signature";
import ServiceFormTemplate from "@/view/pages/service-form/Service-Form-Template.vue";
import ServiceFormDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import CreateServiceForm from "@/view/pages/job/partials/Create-Service-Form.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { DOWNLOAD, QUERY } from "@/core/services/store/request.module";
import { saveAs } from "file-saver";
import { currentUser } from "@/core/services/jwt.service";
import { find } from "lodash";
import ShareServiceReport from "@/view/pages/partials/Share-Service-Report-Mail";
import ObjectPath from "object-path";
import SingleDocumentwithName from "@/view/pages/SingleDocumentwithName";
import ImageTemplate from "@/view/pages/Image";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Barcode,
    Dialog,
    CloserSignature,
    ServiceFormDelete,
    ServiceFormTemplate,
    SingleDocumentwithName,
    ImageTemplate,
    ShareServiceReport,
    CreateServiceForm,
  },
  watch: {
    visit: {
      deep: true,
      immediate: true,
      handler(param) {
        this.filter.visit = this.lodash.toSafeInteger(param.id);
      },
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
        this.getServiceForm();
        this.getEngineerList();
        this.getVisitList();
      },
    },
    /*  queryFilter: {
      deep: true,
      immediate: true,
      handler(param) {
      console.log({param})
      },
    }, */
  },
  data() {
    return {
      serviceForms: [],
      share_dialog: false,
      visitId: 0,
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
      typeId: 0,
      add_document_dialog: false,
      add_signature_dialog: false,
      show_voice_notes: false,
      voice_notes_list: [],
      reportId: 0,
      checkouttimesubmit: false,
      job: 0,
      detailDialog: false,
      pdfDownloading: false,
      serviceFormDialog: false,
      //submitbuttonloginform: false,
      serviceFormInfo: {},
      attributes: [],
      teams: [],
      ServiceFormDeleteDialog: false,
      ServiceFormDeleteRequestURL: "",
      dates: [],
      engineerList: [],
      visitList: [],
      filter: {
        search: null,
        engineer: 0,
        visit: 0,
        trip_id: 0,
      },
      timeout: null,
      timeoutLimit: 500,
      serviceFormLoading: true,
    };
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("DD/MM/YYYY hh:mm A");
    },
    showNotes(data) {
      this.show_voice_notes = true;
      this.voice_notes_list = data.voice_notes;
    },
    share_service_report(row) {
      this.visitId = ObjectPath.get(row, "visit.id", 0);
      this.reportId = row.id;
      this.$nextTick(() => {
        this.share_dialog = true;
      });
    },
    uploadFile(row) {
      this.typeId = row.id;
      this.add_document_dialog = true;
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    getTripList() {
      const _this = this;
      /* let filter = {
          search: _this.filter.search,
          daterange: _this.dates,
        }; */
      _this.$store
        .dispatch(QUERY, {
          url: `visit/${this.visitId}/trips`,
          /*  data:{
                filter:filter
            } */
        })
        .then((output) => {
          this.teams = output.data.rows;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    createServiceForm() {
      this.serviceFormDialog = true;
    },
    closeDialog() {
      this.detailDialog = false;
      this.serviceFormInfo = {};
    },
    serviceFormDetail(param) {
      this.detailDialog = true;
      this.serviceFormInfo = param;
    },
    downloadSingleServiceForm(ticket, title, service_form_id) {
      const _this = this;
      _this.pdfDownloading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${ticket}/service-forms/${service_form_id}/download`,
        })
        .then(({ data }) => {
          saveAs(data, title + ".pdf");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pdfDownloading = false;
        });
    },
    downloadSingleExcelServiceForm(ticket, title, service_form_id) {
      const _this = this;
      _this.pdfDownloading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${ticket}/service-forms/${service_form_id}/excel/download`,
        })
        .then(({ data }) => {
          saveAs(data, title + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pdfDownloading = false;
        });
    },
    downloadServiceForm() {
      const _this = this;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${_this.serviceFormInfo.ticket}/service-forms/${_this.serviceFormInfo.id}/download`,
        })
        .then(({ data }) => {
          saveAs(data, _this.serviceFormInfo.title + ".pdf");
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    downloadServiceExcelForm() {
      const _this = this;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${_this.serviceFormInfo.ticket}/service-forms/${_this.serviceFormInfo.id}/excel/download`,
        })
        .then(({ data }) => {
          saveAs(data, _this.serviceFormInfo.title + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    startSaveForm(param) {
      ServiceFormEventBus.$emit("start:save-service-form", param);
    },
    ServiceFormDeleteSuccess() {
      this.ServiceFormDeleteDialog = false;
      this.ServiceFormDeleteRequestURL = "";
      this.getServiceForm();
      this.closeDialog();
      this.$emit("update:service-form", true);
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getServiceForm();
    },
    deleteServiceForm() {
      this.ServiceFormDeleteDialog = true;
      this.ServiceFormDeleteRequestURL =
        "job/" +
        this.detail.id +
        "/visit/" +
        this.serviceFormInfo.visit.id +
        "/service-form/" +
        this.serviceFormInfo.id;
    },
    getServiceForm() {
      const _this = this;
      let filter = {
        search: _this.filter.search,
        visit: _this.lodash.toSafeInteger(_this.filter.visit),
        engineer: _this.lodash.toSafeInteger(_this.filter.engineer),
        daterange: _this.dates,
        trip: _this.filter.trip_id,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }
      clearTimeout(_this.timeout);
      _this.serviceFormLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getServiceForms(_this.job, { filter })
          .then((response) => {
            _this.serviceForms = response;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.serviceFormDialog = false;
            _this.serviceFormLoading = false;
            _this.detailDialog = false;
            _this.serviceFormInfo = new Object();
          });
      }, _this.timeoutLimit);
    },
    getEngineerList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return false;
      }
      _this
        .getEngineers(_this.job)
        .then(({ rows }) => {
          if (_this.lodash.isEmpty(rows) === false) {
            _this.engineerList = rows.map((row) => {
              return row.engineer;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getBarcode(service) {
      let barcodeStr = this.visit.barcode.split("-");
      barcodeStr = barcodeStr[0];
      let perent = this.visit.barcode.replace(barcodeStr, "SRV");
      return perent + "-" + service.service_barcode;
    },
    getBarcodeBytrip(service) {
      let barcodeStr = this.visit.barcode.split("-");
      barcodeStr = barcodeStr[0];
      let perent = this.visit.barcode.replace(barcodeStr, "S");
      return (
        perent + "-" + service.trip.trip_code + "-" + service.service_barcode
      );
    },
    getVisitList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return false;
      }
      _this
        .getVisitsForFilter(_this.job)
        .then((response) => {
          _this.visitList = response;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    submitbuttonloginform() {
      const user = currentUser();
      //alert(123);

      const role_user = this.lodash.toLower(user.id);
      //console.log(role_user, "role_user");
      //console.log(this.serviceFormInfo, "role_user");
      if (this.serviceFormInfo.added_by.id == role_user) {
        return true;
      } else {
        return false;
      }
    },

    submitChckouthide() {
      if (this.checkout_visit && this.checkout_visit.id) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getServiceForm();
    this.getEngineerList();
    this.getVisitList();
    this.visitId = this.lodash.toSafeInteger(this.$route.params.id);
    if (this.visitId > 0) {
      this.getTripList();
    }

    /* if (this.checkout_visit && this.checkout_visit.id) {
      this.checkouttimesubmit = true;
    } else {
      this.checkouttimesubmit = false;
    } */
  },
  beforeDestroy() {
    /*ServiceFormEventBus.$off("save:service-form");
    ServiceFormEventBus.$off("close:loader");
    ServiceFormEventBus.$off("start:loader");
    ServiceFormEventBus.$off("update:service-form-attributes");*/
  },
  created() {
    const _this = this;

    ServiceFormEventBus.$on("save:service-form", function () {
      _this.getServiceForm();
      _this.detailDialog = false;
      _this.serviceFormInfo = new Object();
    });
    ServiceFormEventBus.$on("close:loader", function () {
      _this.formLoading = false;
    });
    ServiceFormEventBus.$on("reload:service-form", function () {
      _this.getServiceForm();
    });
    ServiceFormEventBus.$on("start:loader", function () {
      _this.formLoading = true;
    });

    ServiceFormEventBus.$on(
      "update:service-form-attributes",
      function (argument) {
        _this.attributes = argument;
      }
    );
  },
  computed: {
    ...mapGetters(["checkout_visit"]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
  },
};
</script>
